<template>
  <div>
    <b-modal
      ref="modal"
      v-model="onControl"
      size="lg"
      title="Create Charge Commission"
      modal-class="modal-primary"
      title-tag="h3"
      @hidden="close"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Select charge"
            >
              <div style="display: grid; grid-template-columns: 12fr .5fr">
                <validation-provider
                  v-slot="{ errors }"
                  name="charge"
                  rules="required"
                >
                  <b-form-select
                    v-model="newCommission.typeChargeId"
                    :options="typesCharges"
                    :state="errors[0] ? false : null"
                  />
                </validation-provider>
                <div class="align-self-center ml-1">
                  <feather-icon
                    class="cursor-pointer text-primary"
                    icon="PlusCircleIcon"
                    size="22"
                    @click="openModalCharge"
                  />
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Description"
            >
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-input
                  v-model="newCommission.description"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Value"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|validate-amount"
                name="Amount"
              >
                <money
                  v-model="newCommission.value"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                    masked: false,
                  }"
                  :class="{ 'border-danger': errors[0] && vmoneyValidate }"
                  class="form-control"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Type"
            >
              <validation-provider
                v-slot="{ errors }"
                name="typeCommission"
                rules="required"
              >
                <b-form-radio-group
                  v-model="newCommission.typeCommission"
                  :class="{
                    'border-danger rounded': errors[0],
                  }"
                  :options="options"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button
          variant="success"
          @click="createCommissionCed"
        >
          Save
        </b-button>
      </template>
    </b-modal>
    <add-charge-modal
      v-if="addChargeModalOn"
      @close="closeModalCharge"
      @refresh="getTypesChargesCommission"
    />
  </div>
</template>

<script>
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import AddChargeModal from '@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/AddChargeModal.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddChargeModal,
  },
  data() {
    return {
      onControl: false,
      addChargeModalOn: false,
      selected: '',
      options: [
        { text: 'Percentage (%)', value: 2 },
        { text: 'Amount ($)', value: 1 },
      ],
      newCommission: {
        description: '',
        value: 0,
        typeCommission: null,
        categoryId: 2,
        moduleId: null,
        createdBy: '',
        userName: '',
        typeChargeId: '',
        showStatus: 3,
      },
      typesCharges: [],
      vmoneyValidate: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    moduleId(){
      return this.$route.matched[0].meta.module
    }
  },
  async created() {
    this.onControl = true
    this.newCommission.createdBy = this.currentUser.user_id
    this.newCommission.userName = `${this.currentUser.first_name} ${this.currentUser.last_name}`
    await this.getTypesChargesCommission()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    closeModalCharge() {
      this.addChargeModalOn = false
    },
    openModalCharge() {
      this.addChargeModalOn = true
    },
    async createCommissionCed() {
      this.vmoneyValidate = true
      const validation = await this.$refs.form.validate()
      if (validation) {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          this.addPreloader();
          this.newCommission.moduleId = this.moduleId == 29 ? 11 : 22;
          const data = await CeSetCommissionsService.createCedCommission(this.newCommission)
          if (data.status === 200) {
            this.close()
            this.$emit('refresh')
            this.showSuccessSwal()
          }
          this.removePreloader();
        }
      }
    },
    async getTypesChargesCommission() {
      let params = {
        moduleId: this.moduleId == 29 ? 11 : 22,
      }
      const data = await CeSetCommissionsService.getTypesChargesCommission(params)
      if (data.status === 200) {
        this.typesCharges = data.data
      }
    },
  },

}
</script>

<style scoped>

</style>
