<template>
  <b-modal
    ref="modal"
    v-model="onControl"
    size="lg"
    title="Create Charge"
    modal-class="modal-primary"
    title-tag="h3"
    @hidden="close"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Description"
        >
          <b-form-input v-model="chargeDescription" />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button
        variant="success"
        @click="createTypeCharge"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CeSetCommissionsService
  from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      onControl: false,
      chargeDescription: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async createTypeCharge() {
      const data = await CeSetCommissionsService.createTypeCharge({
        description: this.chargeDescription,
        moduleId: 22,
        userId: this.currentUser.user_id,
      })
      if (data.status === 200) {
        this.close()
        this.$emit('refresh')
        this.showSuccessSwal()
      }
    },
  },
}
</script>

<style scoped>

</style>
