<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refCommissionsList'].refresh()"
    >
      <template #table>
        <b-table
          ref="refCommissionsList"
          no-border-collapse
          class="position-relative"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="commissionsProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_name)="data">
            {{ data.item.created_name }}
            <br />
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(evaluated_name)="data">
            {{ data.item.evaluated_name }}
            <br />
            {{ data.item.processed_at | myGlobalWithHour }}
          </template>
          <template #cell(module_name)="data">
            <div class="text-info">
              {{ data.item.module_name }}
            </div>
          </template>
          <template #cell(show_status)="data">
            <b-badge
              :variant="getVariantColor(data.item.show_status_num)"
              class="text-center w-100"
            >
              {{ data.item.show_status }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                v-b-modal.modal-action
                v-b-tooltip.hover.top="'Approve'"
                class="button-rounded"
                variant="primary"
                @click="approveDisapproveCreateCommission(data.item, 1)"
              >
                <feather-icon icon="ThumbsUpIcon" size="15" />
              </b-button>
              <b-button
                v-b-modal.modal-action
                v-b-tooltip.hover.top="'Disapprove'"
                class="button-rounded"
                variant="danger"
                @click="approveDisapproveCreateCommission(data.item, 2)"
              >
                <feather-icon icon="ThumbsDownIcon" size="15" />
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import CeSetCommissionsService from "@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service";
import DynamicCommissionsFilters from "@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/data/dynamic.commissions.filters";
import { mapGetters } from "vuex";

export default {
  props: {
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      commissionModalOn: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filters: DynamicCommissionsFilters,
      fields: [
        {
          key: "commission_desc",
          label: "Name",
          visible: true,
        },
        {
          key: "value",
          label: "Value",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "type_commission",
          label: "Type commission",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "module_name",
          label: "Module",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "charge_name",
          label: "Charge",
          visible: true,
        },
        {
          key: "created_name",
          label: "Created By",
          visible: true,
        },
        {
          key: "evaluated_name",
          label: "Processed By",
          visible: true,
        },
        {
          key: "show_status",
          label: "Status",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          visible: true,
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: "created_at",
      sortDesc: true,
      isBusy: false,
      items: [],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((column) => column.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    setModuleId() {
      let module = 22;
      if (this.moduleId == 29) module = 11;
      if (this.moduleId == 16) module = null;
      return module;
    },
  },
  watch: {
    async status() {
      await this.$refs.refCommissionsList.refresh();
      this.filterColumn(
        "evaluated_name",
        this.status === 2 || this.status === null
      );
      this.filterColumn(
        "show_status",
        this.status === 2 || this.status === null
      );
      this.filterColumn("actions", this.moduleId === 16 && this.status === 1);
    },
  },
  created() {
    this.filterColumn(
      "evaluated_name",
      this.status === 2 || this.status === null
    );
    this.filterColumn("show_status", this.status === 2 || this.status === null);
    this.filterColumn("actions", this.moduleId === 16 && this.status === 1);
    this.filterColumn("module_name", this.moduleId === 16);
  },
  methods: {
    openModalCreateCommission() {
      this.commissionModalOn = true;
    },
    async commissionsProvider(ctx) {
      const params = {
        perPage: ctx.perPage,
        orderby: "created_at",
        page: ctx.currentPage,
        order: ctx.sortDesc == 1 ? "desc" : "asc",
        text: this.filterPrincipal.model,
        date_from: this.filters[0].model,
        date_to: this.filters[1].model,
        moduleId: this.setModuleId,
        status: this.status,
      };
      const data = await CeSetCommissionsService.listDynamicCommissionsCed(
        params
      );
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    getVariantColor(status) {
      switch (status) {
        case 1:
          return "light-success";
        case 2:
          return "light-danger";
        case 3:
          return "light-info";
        default:
          return "light-info";
      }
    },
    async approveDisapproveCreateCommission(item, decision) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data =
            await CeSetCommissionsService.approveDisapproveDynamicCommission({
              userId: this.currentUser.user_id,
              createdById: item.created_by_id,
              status: decision,
              dynamicCommissionId: item.dynamic_commission_id,
              commissionType: item.commission_id,
              userName: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
              typeCommissionDescription: item.commission_desc,
              value: item.value,
              typeDataCommission: item.type_commission_num,
              chargeName: item.charge_name,
            });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$refs.refCommissionsList.refresh();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    refTable() {
      this.$refs.refCommissionsList.refresh();
    },
  },
};
</script>

<style scoped>
.button-rounded {
  padding: 0;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin: 5px;
}
</style>
