<template>
  <div>
    <b-row class="d-flex k-justify-content-end mb-1">
      <b-button
        variant="success"
        @click="openModalCreateCommission"
        class="mr-3"
      >
        <feather-icon icon="PlusIcon" /> Create
      </b-button>
    </b-row>
    <dynamic-commissions-table
      ref="dynamic-commission"
      :status="null"
    />
    <add-commission-modal
      v-if="commissionModalOn"
      @refresh="$refs['dynamic-commission'].refTable"
      @close="commissionModalOn = false"
    />
  </div>
</template>

<script>
import AddCommissionModal from '@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/AddCommissionModal.vue'
import DynamicCommissionsTable from '@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/table/DynamicCommissionsTable.vue'
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'

export default {
  name: 'DynamicCommissions',
  components: {
    AddCommissionModal,
    DynamicCommissionsTable,
  },
  data() {
    return {
      commissionModalOn: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      fields: [
        {
          key: 'commission_desc',
          label: 'Name',
        },
        {
          key: 'value',
          label: 'Value',
        },
        {
          key: 'type_commission',
          label: 'Type commission',
        },
        {
          key: 'charge_name',
          label: 'Charge',
        },
        {
          key: 'created_name',
          label: 'Created By',
        },
        {
          key: 'evaluated_name',
          label: 'Processed By',
        },
        {
          key: 'show_status',
          label: 'Status',
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'name',
      sortDesc: true,
      isBusy: false,
      items: [],
    }
  },
  methods: {
    openModalCreateCommission() {
      this.commissionModalOn = true
    },
    async commissionsProvider(ctx) {
      const params = {
        perPage: ctx.perPage,
        orderby: 'name',
        page: ctx.currentPage,
        order: ctx.sortDesc == 1 ? 'desc' : 'asc',
        campo: this.filterPrincipal.model,
      }
      const data = await CeSetCommissionsService.listDynamicCommissionsCed(params)
      this.items = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.nextPage = this.startPage + 1
      this.endPage = data.data.last_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      return this.items || []
    },
    getVariantColor(status) {
      switch (status) {
        case 1:
          return 'light-success'
        case 2:
          return 'light-danger'
        case 3:
          return 'light-info'
        default:
          return 'light-info'
      }
    },
  },
}
</script>

<style scoped>

</style>
